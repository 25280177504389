//
// Custom Styling
//

// init Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// import Pages
@import './pages/homepage.scss';
@import './pages/invitation-page.scss';
@import './pages/what-we-do-page.scss';
@import './pages/whats-included-page.scss';
@import './pages/who-we-are-page.scss';
@import './pages/pricing-page.scss';
@import './pages/thank-you-page.scss';
@import './pages/faqpage.scss';
@import './pages/login-page.scss';
@import './pages/registration-page.scss';
@import './pages/forgot-password-page.scss';
@import './pages/policies/policypages.scss';

// import Partials
@import './partials/faq.scss';
@import './partials/charge-price-container.scss';

// import Components

@import './components/navbar.scss';
@import './components/footer.scss';
@import './components/plan-selector.scss';

// import Existing Component Updates
@import './existing/aside-menu.scss';
@import './existing/company-setup.scss';
@import './existing/dashboard.scss';

// importing Dash Page Components
@import './existing/account-page.scss';
@import './dashboard/billing-page.scss';
@import './dashboard/macros-settings-page.scss';
@import './dashboard/macros-snippets-page.scss';
@import './dashboard/team-page.scss';
@import './admin-dashboard/style.scss';
@import './dashboard/ai-page.scss';

// importing Dash Widgets
@import './dashboard/widgets/ticket-count-widget.scss';


.hidden {
  display: none;
}
.error-message {
  color: red;
  font-size: 1rem;
  font-weight: 500;
}
.custom-page {
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  overflow-x: hidden;
}
.btn-primary {
  font-size: 1.2rem;
  background-color: #100f0d!important;
  min-width: 12.5rem;
  height: 4rem;
  line-height: 2.5rem;
  border: none!important;
  --bs-btn-border-radius: 0.1rem;
  padding-left: 3rem!important;
  padding-right: 3rem!important;
  --kt-primary-active: #100f0d!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-light-primary {
  border: none!important;
  --bs-btn-border-radius: 0.1rem;
  height: 4rem;
  font-size: 1.2rem;
}
.btn.btn-primary:hover:not(.btn-active) {
  background-color: #4855ae!important;
}
.btn-active-light-primary {
  --bs-btn-border-radius: 0.1rem;
  height: 4rem;
}
h1, h2 {
  color: #100f0d;
  font-size: 3.25rem;
  margin-top: 2rem;
}
h3 {
  color: #100f0d;
  font-size: 1.75rem;
  font-weight: 600;
}
h4 {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
}
select {
  background-color: #ffffff;
  border: 1px solid #d9dee1!important;
}
.paragraph-text {
  color: #100f0d;
  font-size: 1.55rem;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  max-width: 40rem;
}
// General styling
.outline-dashed {
  border: 1px dashed var(--kt-gray-800);
}

.loading-icon {
  height: 1rem;
  justify-self: center;
  align-self: center;
}

/* Solid line divider */
hr.solid {
  border-top: 1px solid #bbb;
}

// some global dashboard stylings
.card-body {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.35rem;
  border-top: none!important;
  min-width: 30rem;
  .dismissal-heading {
    color: red;
  }
  .dismissal-text {
    color: #100f0d;
    font-size: 1.25rem;
  }
  .dismissal-button {
    float: right;
  }
}

// TODO: maybe move this Top Bar stuff elsewhere
// Top Bar
.settings-icon {
  transition: transform .75s ease-in-out;
  width: 2.25rem!important;
  height: 2.25rem!important;
}
.settings-icon:hover {
  transform: rotate(180deg);
}

// screen size stylings
.not-desktop {
  display: none;
}

// Tablet & Mobile
@include media-breakpoint-down(lg) {
  .desktop-only {
    display: none;
  }
  .not-desktop {
    display: block;
  }
  // Bootstrap
  div[class*=" col-md-"] {
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
